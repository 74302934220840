// ### VARIABLES INITIALIZATION
import { PROPERTY_TYPE_DEFAULT } from '~/config/property-types';

export function generateSeo() {
  const { t } = useI18n();
  const propertyTypeObj = PROPERTY_TYPE_DEFAULT;
  const getMetaData = (seoData, extraData = {}, replacements = {}, isGeneric = false) => {
    if (isGeneric) {
      replacements = {
        PROPERTYTYPE: t(propertyTypeObj?.transcode_s),
        PROPERTYTYPES: t(propertyTypeObj?.transcode),
        YEAR: new Date().getFullYear(),
      };
    }

    const title = seoData?.title || seoData?.ogTitle || t('t_DEFAULTSEOTITLE');
    const ogTitle = seoData?.ogTitle || seoData?.title || t('t_DEFAULTSEOTITLE');
    const description
      = (seoData?.description || seoData?.ogDescription)?.replace(/\r?\n|\r/g, '') || t('t_DEFAULTSEODESCRIPTION');
    const ogDescription
      = (seoData?.ogDescription || seoData?.description)?.replace(/\r?\n|\r/g, '') || t('t_DEFAULTSEODESCRIPTION');
    const ogImage = extraData?.ogImage
      ? extraData?.ogImage
      : 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_600/v1647449187/pwa/hostelworld.png';

    const result = {
      title,
      ogTitle,
      description,
      ogDescription,
      canonicalUrl: extraData?.canonicalUrl,
      ogImage,
      keywords: seoData?.keywords || '',
      copy: seoData?.copy || null,
      heading: seoData?.heading || null,
      h1: seoData?.h1 || null,
      h2: seoData?.h2 || null,
    };

    const transcodeInterpolate = new TranscodeInterpolate();
    const propertiesToReplace = [
      'title',
      'description',
      'copy',
      'ogTitle',
      'ogDescription',
      'heading',
      'keywords',
      'h1',
      'h2',
    ];
    propertiesToReplace.forEach((property) =>
      result?.[property]
        ? (result[property] = transcodeInterpolate.interpolate(result[property], replacements))
        : (result[property] = ''),
    );

    return result;
  };

  return { buildSeo: { getMetaData } };
}
